// export function throttle(callback, limit) {
//   let waiting = false; // Initially, we're not waiting
//   return function () {
//     // We return a throttled function
//     if (!waiting) {
//       // If we're not waiting
//       callback.apply(this, arguments); // Execute users function
//       waiting = true; // Prevent future invocations
//       setTimeout(() => {
//         // After a period of time
//         waiting = false; // And allow future invocations
//       }, limit);
//     }
//   };
// }

export function throttle(func, delay) {
  let timerId;
  return function () {
    // If setTimeout is already scheduled, no need to do anything
    if (timerId) {
      return;
    }

    // Schedule a setTimeout after delay seconds
    timerId = setTimeout(() => {
      func.apply(this, arguments);

      // Once setTimeout function execution is finished, timerId = undefined so that in <br>
      // the next scroll event function execution can be scheduled by the setTimeout
      timerId = undefined;
    }, delay);
  };
}
