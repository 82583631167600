import React from 'react';

export const Feature = ({ title, symbol, number, size, className }) => {
  return (
    <div className={`feature ${size} ${className || ''} grow`}>
      <div className="symbol">{symbol}</div>
      <div className="title">{title}</div>
      <div className="number">{number}</div>
    </div>
  );
};
